import React from "react"

import { graphql, Link } from "gatsby"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import SEO from "../seo"
import ArticleBox from "../solution/ArticleBox"
import BaseLayout from "../layout/baseLayout"

const ArchiveTemplate = (props: {
  pageTitle: string
  headline: string
  description: string
  articleKey: string
}) => {
  const { t, i18n } = useTranslation()

  return (
    <>
      <SEO title={t("About")} isHome={false} />
      <BaseLayout>
        <div className="pb-16 base-container">
          <ArticleBox
            headline={props.headline}
            description={props.description}
            prefix={props.articleKey}
          />
        </div>
      </BaseLayout>
    </>
  )
}

export default ArchiveTemplate
