import React, { useEffect, useRef } from "react"

import { graphql, useStaticQuery } from "gatsby"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { langFileFilter } from "../../util/langUtils"
import BlogCard from "../common/BlogCard"
import { Link } from "gatsby"
import { sr, srConfig } from "../../util/sr"

const ArticleBox = ({
  count,
  prefix,
  description,
  headline,
  archiveLink,
  archiveButtonName,
  noDate,
  className
}: {
  prefix?: string | string[]
  count?: number
  headline?: string
  description?: string
  archiveLink?: string
  archiveButtonName?: string
  noDate?: boolean
  className?: string
}) => {
  const { i18n } = useTranslation()
  const { language } = i18n

  const headlineRef = useRef(null)
  const articleRefs = [useRef(null), useRef(null), useRef(null)]

  useEffect(() => {
    if (
      count <= 3 &&
      sr &&
      !articleRefs.some(ref => !ref.current)
    ) {
      sr.reveal(articleRefs[0].current, srConfig(200))
      sr.reveal(articleRefs[1].current, srConfig(400))
      sr.reveal(articleRefs[2].current, srConfig(600))
    }
    if (
      sr && headlineRef.current
    ) {
      sr.reveal(headlineRef.current, srConfig())
    }
      
  }, [])

  const data = useStaticQuery(graphql`
    query {
      article: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/markdown-pages/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              slug
              websiteLink
              category
              date
              image {
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              readingTime
              summary
              title
            }
            fileAbsolutePath
          }
        }
      }
    }
  `)
  const filteredArticles = langFileFilter(data.article.edges, language)
    .filter(article =>
      (typeof prefix === "string" ? [prefix] : prefix).some(p =>
        article.node.fileAbsolutePath.includes(p)
      )
    )
    .sort(
      (a, b) =>
        new Date(b.node.frontmatter.date).getTime() -
        new Date(a.node.frontmatter.date).getTime()
    )
  const displayArticles = filteredArticles.splice(0, count ?? 99)

  return (
    <div className={`${className ?? ''}`}>
      <div className="pt-4 base-container">
        <div
          ref={headlineRef}
          className="max-w-5xl px-4 py-12 mx-auto sm:py-16 sm:px-6 lg:px-8 load-hidden"
        >
          {headline && <h1 className="text-center">{headline}</h1>}
          {description && (
            <p
              className="mt-4 text-center"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-12">
          {displayArticles.map(
            (
              {
                node: {
                  frontmatter: {
                    slug,
                    websiteLink,
                    category,
                    date,
                    image,
                    readingTime,
                    summary,
                    title,
                  },
                },
              },
              i
            ) => (
              <div key={title} ref={count <= 3 ? articleRefs[i] : undefined}>
                <BlogCard
                  slug={slug}
                  websiteLink={websiteLink}
                  category={category}
                  date={noDate ? "" : date}
                  image={image}
                  readingTime={readingTime}
                  summary={summary}
                  title={title}
                />
              </div>
            )
          )}
        </div>
      </div>
      {archiveLink && archiveButtonName && !!filteredArticles.length && (
        <div className="flex justify-center base-container">
          <Link to={archiveLink} className="mx-auto mt-16 mb-12 btn-outline">
            {archiveButtonName}
          </Link>
        </div>
      )}
    </div>
  )
}

export default ArticleBox
