import { Link } from "gatsby"
import React from "react"
import { Trans, useTranslation } from "react-i18next"
import Img from "gatsby-image"

interface IBlogCardProps {
  category: string
  title: string
  summary: string
  image: any
  date: Date | string | number
  readingTime: string
  slug: string
  websiteLink: string
}

const BlogCard = ({
  category,
  date,
  image,
  readingTime,
  summary,
  title,
  slug,
  websiteLink,
}: IBlogCardProps) => {
  const { t, i18n } = useTranslation()
  return (
    <div
      key={title}
      className="flex flex-col h-full rounded-lg shadow-lg border overflow-hidden"
    >
      <div className="flex-shrink-0">
        <Img
          className="aspect-blog w-full object-cover"
          fluid={image.childImageSharp.fluid}
          alt=""
        />
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="text-sm font-medium text-primary uppercase">
            {category}
          </p>
          {slug && (
            <Link to={slug} className="block mt-2">
              <p className="text-xl font-semibold text-gray-900">{title}</p>
              <p className="mt-3 text-base text-gray-500">{summary}</p>
              <p className="text-primary">
                <Trans>Read-more</Trans> &#8594;
              </p>
            </Link>
          )}
          {!slug && websiteLink && (
            <a
              href={websiteLink}
              className="block mt-2"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <p className="text-xl font-semibold text-gray-900">{title}</p>
              <p className="mt-3 text-base text-gray-500">{summary}</p>
              <p className="text-primary">
                <Trans>Read-more</Trans> &#8594;
              </p>
            </a>
          )}
          {!slug && !websiteLink && (
            <div>
              <p className="text-xl font-semibold text-gray-900">{title}</p>
              <p className="mt-3 text-base text-gray-500">{summary}</p>
            </div>
          )}
        </div>
        <div className="mt-6 flex items-center">
          <div className="flex space-x-1 text-sm text-primary">
            {date && (
              <time dateTime={new Date(date).toISOString()}>
                {new Date(date).toLocaleDateString(i18n.language)}
              </time>
            )}
            {readingTime && (
              <div>
                <span aria-hidden="true">&#124;</span>{" "}
                {i18n.language === "de" ? "Lesezeit " : ""}
                {readingTime} {i18n.language === "en" ? "read" : ""}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogCard
