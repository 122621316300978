export const langFileFilter = (
  items: Array<{ node: { fileAbsolutePath?: string; [key: string]: any } }>,
  language: string
) => {
  return items.filter(element => {
    const filename = element.node.fileAbsolutePath.substring(
      element.node.fileAbsolutePath.lastIndexOf('/') + 1
    )
    const fileLang =
      (filename.match(/\./g) || []).length === 1
        ? 'de'
        : filename.substr(filename.indexOf('.') + 1, 2)
    return fileLang === language
  })
}
